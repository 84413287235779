import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";
import {dialogFlowAxios} from "@/main";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useSettings} from "@/stores/settings";

export const useIntentStore = defineStore({
    id: 'intents',

    state: () => ({
        helpMessageMapOrigin: "Se non viene inserito nessuno indirizzo, in chat verrà impostata la posizione dell'utente",
        helpMessageVideo: 'Incolla il link da Youtube',
        helpMessageEndInteraction: "Se il percorso è di fine conversazione, la sezione 'Suggerimenti' verrà disabilitata e resettata",
        helpMessageSuggestion: "Puoi modificare la posizione di ogni singolo suggerimento",
        currentTypeIntent: 'list',
        followupFromListActive: false,
        selectedChoiceFollowup: null,
        genericAction: false,
        intents: null,
        countTotList: 0,
        currentPageList: 1,
        intent: null,
        addIntent: false,
        selectedPartial: false,
        selectedIntents: [],
        arrayIntentsToDelete: [],
        errorMessage: '',
        optionsOther: [
            {
                value: "m",
                display_name: "Mappa"
            },
            {
                value: "v",
                display_name: "Video"
            }
        ],
        optionsMapMode: [
            {
                value: "directions",
                display_name: "Percorso"
            },
            {
                value: "place",
                display_name: "Segnaposto"
            },
            {
                value: "search",
                display_name: "Ricerca in un'area"
            }
        ],
        // differenzia i due casi interni se scegli 'Percorso'
        optionsDirectionCustomMode: [
            {
                value: "static",
                display_name: "Statico"
            },
            {
                value: "dynamic",
                display_name: "Dinamico"
            }
        ],
        optionsDirectionMode: [
            {
                value: "driving",
                display_name: "Macchina"
            },
            {
                value: "walking",
                display_name: "A piedi"
            },
            {
                value: "bicycling",
                display_name: "Bicicletta"
            },
            {
                value: "transit",
                display_name: "Mezzi di trasporto"
            },
            {
                value: "flying",
                display_name: "Aereo"
            }
        ],
    }),
    actions: {
        getIntents() {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }

                dialogFlowAxios.get('v2/projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/intents?intentView=INTENT_VIEW_FULL', config)
                    .then(res => {
                        this.intents = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        } else if (err.response && err.response.data.error.code === 429) {
                            useApiSettingStore().error500 = err.response.data.error.message
                        }
                        reject(err)
                    })


            })
        },
        getIntent(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }

                console.log('paylad', payload)

                let url = new URL(dialogFlowAxios.defaults.baseURL + payload.name)
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    dialogFlowAxios.get('v2' + url.pathname + url.search, config)
                        .then(res => {
                            this.intent = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    dialogFlowAxios.get('v2/' + url.pathname, config)
                        .then(res => {
                            this.intent = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        createIntent(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.post('v2/projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/' + 'intents?intentView=INTENT_VIEW_FULL', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        let errorSplit = err.response.data.error.message.split(':')
                        if (errorSplit.length > 1) {
                            let text = ''
                            for (let i = 1; i < errorSplit.length; i++) {
                                text = text.concat(' ', errorSplit[i])
                            }
                            this.errorMessage = text
                        } else {
                            this.errorMessage = err.response.data.error.message
                        }
                        reject(err)
                    })
            })
        },
        editIntent(payload) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.patch('v2/' + payload.name, payload, config)
                    .then(res => {
                        this.intent = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        let errorSplit = err.response.data.error.message.split(':')
                        if (errorSplit.length > 1) {
                            let text = ''
                            for (let i = 1; i < errorSplit.length; i++) {
                                text = text.concat(' ', errorSplit[i])
                            }
                            this.errorMessage = text
                        } else {
                            this.errorMessage = err.response.data.error.message
                        }
                        reject(err)
                    })
            })
        },
        batchDeleteIntent(arrayIntent) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }
                dialogFlowAxios.post('v2/projects/' + useSettings().serviceAccountCredentialsGetters.setting_records.project_id.value + '/agent/intents:batchDelete', {
                    "intents": arrayIntent
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteIntent(intent) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().bearerTokenGetters
                    }
                }

                dialogFlowAxios.delete('v2/' + intent, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        intentsGetters: state => {
            return state.intents
        },
        intentGetters: state => {
            return state.intent
        },
        addIntentGetters: state => {
            return state.addIntent
        },
        countTotListGetters: state => {
            return state.countTotList
        },
        errorMessageGetters: state => {
            return state.errorMessage
        },
        currentPageListGetters: state => {
            return state.currentPageList
        },
        selectedPartialGetters: state => {
            return state.selectedPartial
        },
        selectedIntentsGetters: state => {
            return state.selectedIntents
        },
        genericActionGetters: state => {
            return state.genericAction
        },
        selectedChoiceFollowupGetters: state => {
            return state.selectedChoiceFollowup
        },
        followupFromListActiveGetters: state => {
            return state.followupFromListActive
        },
        currentTypeIntentGetters: state => {
            return state.currentTypeIntent
        },
        helpMessageEndInteractionGetters: state => {
            return state.helpMessageEndInteraction
        },
        helpMessageSuggestionGetters: state => {
            return state.helpMessageSuggestion
        },
        helpMessageVideoGetters: state => {
            return state.helpMessageVideo
        },
        optionsOtherGetters: state => {
            return state.optionsOther
        },
        arrayIntentsToDeleteGetters: state => {
            return state.arrayIntentsToDelete
        },
        optionsMapModeGetters: state => {
            return state.optionsMapMode
        },
        optionsDirectionModeGetters: state => {
            return state.optionsDirectionMode
        },
        optionsDirectionCustomModeGetters: state => {
            return state.optionsDirectionCustomMode
        },
        helpMessageMapOriginGetters: state => {
            return state.helpMessageMapOrigin
        }
    }
})


